import { breakpointList } from "../helpers"
export const swatches = {
  primary: {
    red: "#cc5c45",
    teal: "#9accc4",
    green: "#b0cca7",
    tan: "#cfc9bc",
  },
  dark: {
    red: "#b53531",
  },
  secondary: {
    background: "#f7f6f2",
    lightTan: "#e6e3dc",
    darkTan: "#807C74",
    tan: "#999687",
    dark: "#2A2D2E",
    black: "#000000",
  },
}
export const styles = {
  input: {
    fontSize: "16px",
    border: "1px solid #e8e6e6",
    borderBottomColor: "#a5a5a5",
    minHeight: "46px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    py: "9px",
    px: "14px",
    boxSizing: "border-box",
    ":focus": {
      borderBottomColor: "primary",
    },
  },
}
export default {
  useCustomProperties: false,
  colors: {
    text: swatches.secondary.black,
    primary: swatches.primary.red,
    secondary: swatches.dark.red,
    muted: swatches.primary.tan,
    background: swatches.secondary.background,
    backgrounds: {
      primary: swatches.secondary.background,
      secondary: swatches.secondary.lightTan,
    },
    swatches,
  },
  fonts: {
    body: "Fabriga, sans-serif",
    heading: "Fabriga, sans-serif",
  },
  fontWeights: {
    body: 400,
    bold: 700,
    heading: 500,
    light: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [0, 12, 18, 21, 24, 40, 56],
  space: [0, 4, 8, 16, 32, 48, 64, 128, 256, 512],
  spacing: {
    horizontal: [20, 20, 60],
    vertical: {
      sm: 30,
      md: 50,
      lg: 100,
      xl: 180,
    },
  },
  buttons: {
    primary: {
      fontSize: 4,
      pl: 30,
      pr: 75,
      py: 15,
    },
    secondary: {
      fontSize: 1,
      pl: 25,
      pr: 75,
      py: 12,
    },
    tertiary: {
      fontSize: 1,
      pl: 20,
      pr: 45,
      py: 2,
    },
  },
  breakpoints: breakpointList,
  sizes: {
    header: {
      height: 90,
    },
  },
  transitions: "0.25s ease-in-out",
}
