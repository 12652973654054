/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "../link"
import { swatches } from "../../gatsby-plugin-theme-ui"

function SectionMobile({ title, links, forceExternal, ...restProps }) {
  return (
    <div
      sx={{
        width: "100%",
        display: "grid",
        ml: ["10px", 0],
      }}
    >
      <h6
        sx={{
          textTransform: "uppercase",
          color: swatches.secondary.darkTan,
          fontSize: "15px",
          ml: ["20px", 0],
          fontWeight: "500",
        }}
      >
        {title}
      </h6>
      <div
        sx={{
          display: "grid",
          gridAutoFlow: ["unset", "column"],
        }}
        {...restProps}
      >
        {links.map((link, index) => (
          <div
            key={index}
            sx={{
              width: "120px",
              marginBottom: ["15px", "15px"],
              ml: ["12%", 0],
            }}
          >
            <Link
              link={link.url}
              {...(forceExternal && { forceExternal: true, target: "_self" })}
              sx={{
                color: swatches.secondary.dark,
                padding: 0,
                fontSize: "17px",
                fontWeight: "400",
              }}
            >
              {link.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionMobile
