import React from "react"
import * as ics from "ics"

const breakpointList = ["576px", "768px", "992px", "1200px", "1600px", "1800px"]

/**
 * Return a list of min-width media queries based on project's breakpoint list.
 * @param mediaPrefix If set to false, return media query without "@media" prefix.
 * @returns {string[]}
 */
const mediaQueryList = ({ mediaPrefix = true } = {}) =>
  breakpointList.map(breakpoint => {
    const mediaDefinition = `(min-width: ${breakpoint})`

    return mediaPrefix ? `@media ${mediaDefinition}` : mediaDefinition
  })

/**
 * Return boolean indicating if element contains the class name.
 * @param content Content card or Blade
 * @param className Class Name to be checked
 * @returns {boolean}
 */
const hasClass = (content, className) =>
  !!(content.classes && content.classes.includes(className))

/**
 * Return a list of values based on breakpointValue for breakpoints matching
 * responsiveList against project's breakpoint list and a list of values based on
 * defaultValue for those who don't.
 * @param breakpointValue Value to be set if breakpoint is matched.
 * @param defaultValue Value to be set if breakpoint isn't matched.
 * @param responsiveList If it's a populated Array, check for breakpoint matches, otherwise return defaultValue.
 * @returns {(Array|any)} List of values representing breakpointValue/defaultValue or defaultValue.
 */
const getResponsiveValueList = ({
  breakpointValue,
  defaultValue,
  responsiveList,
}) => {
  return responsiveList &&
    Array.isArray(responsiveList) &&
    responsiveList.length
    ? responsiveList.map(breakpointMatch =>
        breakpointMatch ? breakpointValue : defaultValue
      )
    : defaultValue
}

/**
 * Change "\n" occurrences to <br /> tags.
 * @param text
 * @returns {(String|null)} Text with line break tags or null.
 */
const renderLineBreak = text => {
  return text && typeof text === "string"
    ? text.split(/\n|\\n/).reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    : null
}

/**
 * Searchs for an object in the list by it's slug
 * @param list
 * @param slug
 * @returns {(Object|null)}
 */
const findBySlug = ({ list, slug }) => {
  return list.find(data => data.slug === slug)
}

/**
 * Returns a background url list, using desktop image as default
 * if mobile is not provided
 *
 * @param {*} { desktopImage, mobileImage }
 * @returns
 */
const getBackgroundList = ({ desktopImage, mobileImage }) => {
  const desktopBackground = desktopImage
    ? `url(${desktopImage.file.url})`
    : theme => theme.colors.white
  const mobileBackground = mobileImage
    ? `url(${mobileImage.file.url})`
    : desktopBackground

  return [mobileBackground, mobileBackground, desktopBackground]
}

const getTimeOptions = (interval = 30) => {
  const quantity = (60 * 24) / interval
  const timeOptions = []
  for (let item = 0; item < quantity; item++) {
    const moment = item * interval
    let hours = Math.floor(moment / 60)
    let suffix = hours >= 12 ? "PM" : "AM"
    hours = ((hours + 11) % 12) + 1
    let minutes = moment % 60
    timeOptions.push(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")} ${suffix}`
    )
  }
  return timeOptions
}

const formatDateForFilter = string => {
  const date = new Date(string)
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0")

  return month + "/" + day
}

const formatDateForStories = string => {
  const options = {
    year: "numeric",
    month: "long",
  }
  const date = new Date(string).toLocaleString("en", options)

  return date
}

const formatDate = string => {
  const date = new Date(string)
  const dd = String(date.getDate()).padStart(2, "0")
  const mm = String(date.getMonth() + 1).padStart(2, "0")
  const yyyy = date.getFullYear()
  return mm + "/" + dd + "/" + yyyy
}

const formatRequestDate = date => {
  const parsedDate = typeof date === "string" ? new Date(date) : date
  const string = parsedDate.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  })
  const [m, d, yyyy] = string.split(",")[0].split("/")
  const mm = m.padStart(2, "0")
  const dd = d.padStart(2, "0")
  return yyyy + "-" + mm + "-" + dd
}

const formatCardDate = (eventStartDate, eventEndDate) => {
  const startDate = new Date(eventStartDate).toUTCString().split(" ")
  const startDd = startDate[1]
  const startMm = startDate[2]
  const startYyyy = startDate[3]

  if (eventEndDate === undefined)
    return startMm + " " + startDd + ", " + startYyyy

  const endDate = new Date(eventEndDate).toUTCString().split(" ")
  const endDd = endDate[1]
  const endMm = endDate[2]
  const endYyyy = endDate[3]
  if (startDd === endDd && startMm === endMm && startYyyy === endYyyy)
    return startMm + " " + startDd + ", " + startYyyy
  else if (startDd !== endDd && startMm === endMm && startYyyy === endYyyy)
    return startMm + " " + startDd + " - " + endDd + ", " + endYyyy
  else if (startYyyy !== endYyyy)
    return (
      startMm +
      " " +
      startDd +
      ", " +
      startYyyy +
      " - " +
      endMm +
      " " +
      endDd +
      ", " +
      endYyyy
    )
  else if (startDd !== endDd && startMm === endMm)
    return startMm + " " + startDd + " - " + endDd + ", " + startYyyy
  else if (startMm !== endMm && startYyyy === endYyyy)
    return (
      startMm + " " + startDd + " - " + endMm + " " + endDd + ", " + startYyyy
    )
}

const dateRangeFilter = selectedStartDate => {
  const startDate = new Date(selectedStartDate).toUTCString().split(" ")
  const day = startDate[1]
  const month = startDate[2]
  var weekday = startDate.toLocaleString("en-US", { weekday: "short" })
  var weekdayShort = weekday.slice(0, 3)

  return selectedStartDate ? weekdayShort + ", " + month + " " + day : " "
}

const dateEndRangeFilter = (selectedStartDate, selectedEndDate) => {
  const endDate = new Date(selectedEndDate).toUTCString().split(" ")
  const day = endDate[1]
  const month = endDate[2]
  var weekday = endDate.toLocaleString("en-US", { weekday: "short" })
  var weekdayShort = weekday.slice(0, 3)

  return selectedEndDate
    ? weekdayShort + ", " + month + " " + day
    : dateRangeFilter(selectedStartDate)
}

const getEventCalendarDates = () => {
  const today = formatRequestDate(new Date())
  const nextFriday = (() => {
    const date = new Date()
    date.setDate(date.getDate() + ((((7 - date.getDay()) % 7) + 5) % 7))
    return formatRequestDate(date)
  })()
  const nextSunday = (() => {
    const date = new Date()
    date.setDate(date.getDate() + (((7 - date.getDay()) % 7) % 7))
    return formatRequestDate(date)
  })()
  const monthEnd = (() => {
    const today = new Date()
    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    return formatRequestDate(monthEnd)
  })()
  return { today, nextFriday, nextSunday, monthEnd }
}

const toSlug = string => {
  return string
    ? string
        .toLowerCase()
        .substring(0, 64)
        .split("")
        .filter(char => /^[a-z0-9 ]+$/.test(char))
        .join("")
        .split(" ")
        .join("-")
    : ""
}

const eventToICS = ({
  startDate,
  startTime,
  endDate,
  endTime,
  title,
  description,
  location,
}) => {
  const parsedStartDate = new Date(
    `${startDate.substring(0, 10)} ${startTime || "12:00 AM"} GMT-7`
  )
  const parsedEndDate = new Date(
    `${endDate.substring(0, 10)} ${endTime || "11:58 PM"} GMT-7`
  )
  return ics.createEvent({
    title,
    description,
    ...(location && { location }),
    start: [
      parsedStartDate.getUTCFullYear(),
      parsedStartDate.getUTCMonth() + 1,
      parsedStartDate.getUTCDate(),
      parsedStartDate.getUTCHours(),
      parsedStartDate.getUTCMinutes(),
    ],
    startInputType: "utc",
    end: [
      parsedEndDate.getUTCFullYear(),
      parsedEndDate.getUTCMonth() + 1,
      parsedEndDate.getUTCDate(),
      parsedEndDate.getUTCHours(),
      parsedEndDate.getUTCMinutes(),
    ],
    endInputType: "utc",
  })
}
const getSubsets = (array, sizes) => {
  let total = sizes.reduce((acum, val) => acum + val)
  let subsets = []
  for (let i = 0; i < array.length; i += total) {
    subsets = [...subsets, array.slice(i, i + total)]
  }
  let newSubsets = []
  for (const subset of subsets) {
    let newSubset = []
    let left = [...subset]
    for (const size of sizes) {
      newSubset = [...newSubset, left.slice(0, size)]
      left = left.slice(size, left.length)
    }
    newSubsets = [...newSubsets, newSubset]
  }
  return newSubsets
}
export {
  breakpointList,
  hasClass,
  findBySlug,
  getBackgroundList,
  getResponsiveValueList,
  mediaQueryList,
  renderLineBreak,
  formatDateForStories,
  getTimeOptions,
  formatDateForFilter,
  formatDate,
  formatRequestDate,
  dateRangeFilter,
  dateEndRangeFilter,
  formatCardDate,
  getEventCalendarDates,
  toSlug,
  eventToICS,
  getSubsets,
}
