import Axios from "axios"
import React from "react"

export const useAPI = setResponse => {
  const get = React.useCallback(
    url => {
      setResponse(response => ({ ...response, loading: true }))
      Axios.get(url)
        .then(response => {
          setResponse(prev => ({
            ...prev,
            loading: false,
            data: response.data,
            error: null,
          }))
        })
        .catch(err => {
          setResponse(prev => ({
            ...prev,
            loading: false,
            error: err?.response?.status,
          }))
        })
    },
    [setResponse]
  )
  const post = React.useCallback(
    ({ data, files, callback, url }) => {
      setResponse(postedEntry => ({ ...postedEntry, loading: true }))
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined)
          formData.set(
            key,
            typeof value === "object" && !Array.isArray(value)
              ? JSON.stringify(value)
              : value
          )
      })
      Object.entries(files).forEach(([key, value]) => {
        if (value && value[0]) formData.set(key, value[0])
      })
      Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(data => {
          setResponse(postedEntry => ({
            ...postedEntry,
            loading: false,
            error: null,
            data,
          }))
          callback && callback()
        })
        .catch(err => {
          setResponse(postedEntry => ({
            ...postedEntry,
            loading: false,
            error: err?.response?.status,
          }))
        })
    },
    [setResponse]
  )
  return { get, post }
}
