import React from "react"
import { Global, css } from "@emotion/core"

import galaxieCopernicusSemiBold from "../fonts/GalaxieCopernicus-Semibold.woff"
import galaxieCopernicusSemiBold2 from "../fonts/GalaxieCopernicus-Semibold.woff2"
import galaxieCopernicusSemiBoldItalic from "../fonts/GalaxieCopernicus-SemiboldItalic.woff"
import galaxieCopernicusSemiBoldItalic2 from "../fonts/GalaxieCopernicus-SemiboldItalic.woff2"
import fabrigaRegular from "../fonts/Fabriga-Regular.woff"
import fabrigaRegular2 from "../fonts/Fabriga-Regular.woff2"
import fabrigaMedium from "../fonts/Fabriga-Medium.woff"
import fabrigaMedium2 from "../fonts/Fabriga-Medium.woff2"

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: Galaxie Copernicus SemiBold;
        font-style: normal;
        font-weight: 600;
        src: url(${galaxieCopernicusSemiBold2}) format("woff2"),
          url(${galaxieCopernicusSemiBold}) format("woff");
      }

      @font-face {
        font-family: Galaxie Copernicus SemiBold Italic;
        font-style: normal;
        font-weight: 600;
        src: url(${galaxieCopernicusSemiBoldItalic2}) format("woff2"),
          url(${galaxieCopernicusSemiBoldItalic}) format("woff");
      }

      @font-face {
        font-family: Fabriga;
        font-style: normal;
        font-weight: 400;
        src: url(${fabrigaRegular2}) format("woff2"),
          url(${fabrigaRegular}) format("woff");
      }
      @font-face {
        font-family: Fabriga;
        font-style: normal;
        font-weight: 500;
        src: url(${fabrigaMedium2}) format("woff2"),
          url(${fabrigaMedium}) format("woff");
      }
    `}
  />
)

export default Fonts
