/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Text = ({ children, type: TagName, variant, ...restProps }) => {
  const typeListConfiguration = {
    p: {
      size: 2,
      style: "body",
    },
    h1: {
      size: 7,
      style: "heading",
    },
    h2: {
      size: 6,
      style: "heading",
    },
    h3: {
      size: 5,
      style: "heading",
    },
    h4: {
      size: 3,
      style: "heading",
    },
    h5: {
      size: 1,
      style: "heading",
    },
    h6: {
      size: 3,
      style: "heading",
    },
  }
  const textTypeSetting = variant
    ? typeListConfiguration[variant].style
    : typeListConfiguration[TagName].style

  return (
    <TagName
      sx={{
        color: "text",
        fontFamily: textTypeSetting,
        fontSize: variant
          ? typeListConfiguration[variant].size
          : typeListConfiguration[TagName].size,
        fontWeight: textTypeSetting,
        lineHeight: textTypeSetting,
        m: 0,
        ...((TagName !== "p" || (TagName === "p" && variant)) && {
          letterSpacing: "-0.03571428571428571em",
        }),
        a: {
          color: "primary",
          textDecoration: "none",
        },
      }}
      {...restProps}
    >
      {children}
    </TagName>
  )
}

Text.defaultProps = { type: "p" }

Text.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  type: PropTypes.oneOf(["p", "h1", "h2", "h3", "h4", "h5", "h6"]),
  variant: PropTypes.oneOf(["p", "h1", "h2", "h3", "h4", "h5", "h6"]),
}

export default Text
