/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const Accent = ({ colorA, colorB, type, children, ...restProps }) => {
  const Polygon = ({ shape, ...restProps }) => {
    return (
      <svg
        height={shape.height}
        width={shape.width}
        viewBox={shape.viewBox}
        preserveAspectRatio={shape.preserveAspectRatio}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
        {...restProps}
      >
        <polygon points={shape.points} sx={{ fill: shape.colorPolygon }} />
      </svg>
    )
  }
  return (
    <div
      sx={{
        position: "relative",
        color: "background",
        backgroundColor: colorB,
      }}
    >
      {type !== "header" && (
        <React.Fragment>
          <Polygon
            shape={{
              height: "320",
              width: "190",
              viewBox: "0 0 190 320",
              points: "0,0 0,320 76,320 190,0",
              colorPolygon: colorA || "swatches.secondary.darkTan",
              preserveAspectRatio: "xMinYMid meet",
            }}
            sx={{
              display: ["none", "none", "none", "block"],
            }}
          />
          <Polygon
            sx={{
              display: ["block", "block", "block", "none"],
            }}
            shape={{
              height: "320",
              width: "114",
              viewBox: "0 0 114 320",
              points: "0,0 0,320 0,320 114,0",
              colorPolygon: colorA || "swatches.secondary.darkTan",
              preserveAspectRatio: "xMinYMid meet",
            }}
          />
        </React.Fragment>
      )}
      {type === "header" && (
        <Polygon
          sx={{
            display: ["none", "none", "block", "block"],
          }}
          shape={{
            height: "320",
            width: "400",
            viewBox: "0 0 400 320",
            points: "0,0 0,320 310,320 400,0",
            colorPolygon: colorA || "swatches.primary.tan",
            preserveAspectRatio: "xMinYMid meet",
          }}
        />
      )}
      <div
        sx={{
          position: "relative",
          zIndex: 1,
          width: "100%",
          pr: [0, 0, 0, 0],
        }}
      >
        <div {...restProps}>{children}</div>
      </div>
    </div>
  )
}

export default Accent
