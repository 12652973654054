/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

import Fonts from "./fonts"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Fonts />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          fontFamily: "body",
          backgroundColor: "backgrounds.primary",
          "& *:focus": {
            outline: 0,
          },
        }}
      >
        <Header />
        <main sx={{ flexGrow: 1 }}>{children}</main>
        <Footer />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
