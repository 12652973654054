/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "../link"
import { hasClass } from "../../helpers"
import { swatches } from "../../gatsby-plugin-theme-ui"
import querystring from "query-string"
import SectionMobile from "./mobile-section"
import SectionMobileGeneral from "./mobile-section-general"

function SubMenuContentMobile({ cards, data }) {
  return (
    <div
      sx={{
        display: "flex",
        margin: "0 auto",
        pt: "24px",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: ["center", "flex-start"],
        width: ["85%", "95%"],
      }}
    >
      {cards.map((card, index) => {
        if (card.classes && card.classes.includes("categories"))
          return (
            <div
              sx={{
                height: "100%",
                width: "50%",
                pl: [0, "30px"],
              }}
            >
              <SectionMobile
                key={index}
                title={card.title}
                card={card}
                links={data.categories.nodes.map(category => ({
                  title: "/" + category.disciplineName,
                  url:
                    "/calendar?" +
                    querystring.stringify({
                      discipline: category.disciplineName,
                    }),
                }))}
                sx={{
                  gridTemplateRows: ["unset", "repeat(5, 1fr)"],
                }}
              />
            </div>
          )
        if (card.classes && card.classes.includes("experiences"))
          return (
            <div sx={{ height: "100%", width: "50%", pl: [0, "25px"] }}>
              <SectionMobile
                key={index}
                title={card.title}
                links={data.experiences.nodes.map(experience => ({
                  title: experience.experienceName,
                  url:
                    "/calendar?" +
                    querystring.stringify({
                      experiences: experience.experienceName,
                    }),
                }))}
                sx={{
                  gridTemplateRows: ["unset", "repeat(4, 0.5fr) "],
                }}
              />
            </div>
          )
        return (
          <div sx={{ width: "100%" }}>
            <SectionMobileGeneral
              key={index}
              title={card.title}
              links={card.contentCards.map(subcard => ({
                title: subcard.title,
                url: subcard.url,
                classes: subcard.classes,
              }))}
            />
            {card.contentCards.map((subcard, index) => (
              <div
                key={index}
                sx={{
                  pl: ["35px", "30px"],
                  mt: "20px",
                  mb: "30px",
                  mr: ["20px", 0],
                }}
              >
                {hasClass(subcard, "submit-event") && (
                  <Link
                    link={subcard.url}
                    sx={{
                      fontSize: "17px",
                      color: swatches.secondary.dark,
                      fontWeight: "400",
                    }}
                  >
                    {subcard.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default SubMenuContentMobile
