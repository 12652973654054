/** @jsx jsx */
import { jsx } from "theme-ui"
import { swatches } from "../../gatsby-plugin-theme-ui"
import Link from "../link"

function DesktopSection({ title, links, forceExternal, ...restProps }) {
  return (
    <div
      sx={{
        height: "65%",
        alignSelf: "center",
        marginLeft: "20px",
        borderRight: ["none", "none", "1px solid #C0C0C0"],
        ":last-of-type": { borderRight: "0px" },
      }}
      {...restProps}
    >
      <h6
        sx={{
          fontSize: "15px",
          textTransform: "uppercase",
          color: swatches.secondary.darkTan,
          fontWeight: "500",
        }}
      >
        {title}
      </h6>
      <div
        sx={{
          display: "grid",
          justifyContent: "start",
          height: ["unset", "unset", "80%"],
          marginRight: ["0px", "0px", "10px", "20px"],
          gridAutoFlow: [null, null, "column"],
          gridTemplateRows: [null, null, "repeat(4, 1fr)"],
        }}
      >
        {links.map((link, index) => (
          <div
            key={index}
            sx={{
              width: ["80px", "80px", "118px", "155px"],
              ...(link.classes &&
                link.classes.includes("submit-event") && {
                  display: ["none", "none", "block"],
                  mt: [0, 0, "40px"],
                }),
              ...(link.classes &&
                link.classes.includes("contact-us") && {
                  display: ["none", "none", "block"],
                  mt: [0, 0, "10px"],
                }),
              ...(link.classes &&
                link.classes.includes("mobile-only") && {
                  display: ["block", "block", "none"],
                }),
            }}
          >
            <Link
              link={link.url}
              {...(forceExternal && { forceExternal: true, target: "_self" })}
              sx={{
                fontSize: ["10px", "10px", "12px", "15px"],
                color: swatches.secondary.dark,
                fontWeight: "400",
              }}
            >
              {link.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DesktopSection
