/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "../link"
import { swatches } from "../../gatsby-plugin-theme-ui"

function SectionMobileGeneral({ title, links }) {
  return (
    <div
      sx={{
        borderTop: "1px solid #c0c0c0",
        borderBottom: "1px solid #c0c0c0",
        width: "100%",
        mt: "10px",
        pt: "24px",
        pl: ["35px", "30px"],
        pb: ["20px", "40px"],
        display: "grid",
      }}
    >
      <h6
        sx={{
          textTransform: "uppercase",
          color: swatches.secondary.darkTan,
          fontSize: "15px",
          fontWeight: "500",
        }}
      >
        {title}
      </h6>
      <div
        sx={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: ["1fr 1fr", "repeat(4,1fr)"],
          gridGap: ["15px", 0],
        }}
      >
        {links.map((link, index) => (
          <div
            key={index}
            sx={{
              mt: 0,
              ":last-of-type": { marginTop: "48px", display: "none" },
            }}
          >
            <Link
              link={link.url}
              sx={{
                fontSize: "17px",
                color: swatches.secondary.dark,
                fontWeight: "400",
              }}
            >
              {link.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionMobileGeneral
