/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Link from "./link"
import { hasClass } from "../helpers"
import instagramLogo from "../images/instagram-slim.svg"
import twitterLogo from "../images/twitter-logo.svg"
import facebookLogo from "../images/facebook.svg"
import SiteLogo from "../images/sfarts-logo-big.svg"
import Container from "./container"
import NewsletterSignup from "./newsletter-signup"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        footerItems {
          ...ContentCardData
        }
      }
    }
  `)
  return (
    <footer sx={{ width: "100%" }}>
      <div
        sx={{
          backgroundColor: "#313436",
          minHeight: "172px",
        }}
      >
        <Container
          sx={{
            backgroundColor: "#313436",
            paddingTop: "40px",
            display: "grid",
            pt: ["37px", "37px", "40px"],
            gridTemplateColumns: ["1fr 1fr", "1fr 1fr", "2fr 1fr 1fr"],
            alignItems: "start",
          }}
        >
          <div>
            <Link link="/">
              <img
                src={SiteLogo}
                alt="SFARTS-logo"
                sx={{ width: ["101px", "101px", "130px"] }}
              />
            </Link>
          </div>
          <div sx={{ display: "grid", justifyItems: "start" }}>
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "column", "row"],
                marginBottom: ["8px", "8px", "22px"],
              }}
            >
              {data.contentfulSite.footerItems.map((card, index) => (
                <div
                  key={index}
                  sx={{
                    paddingRight: 0,
                    ":first-of-type": { paddingRight: [0, 0, "36px"] },
                  }}
                >
                  {hasClass(card, "footer-link") && (
                    <Link
                      key={index}
                      sx={{
                        color: "background",
                      }}
                      link={card.url}
                    >
                      {card.title}
                    </Link>
                  )}
                </div>
              ))}
            </div>

            <div sx={{ display: "flex", flexDirection: "row" }}>
              {data.contentfulSite.footerItems.map((card, index) => (
                <div key={index}>
                  {hasClass(card, "twitter-link") && (
                    <Link link={card.url} target="_blank">
                      <img alt="Twitter" src={twitterLogo} width="24px" />
                    </Link>
                  )}
                </div>
              ))}
              {data.contentfulSite.footerItems.map((card, index) => (
                <div key={index}>
                  {hasClass(card, "facebook-link") && (
                    <Link link={card.url} target="_blank">
                      <img
                        alt="Facebook"
                        src={facebookLogo}
                        sx={{ mx: ["24px", "24px", "30px"] }}
                        width="24px"
                      />
                    </Link>
                  )}
                </div>
              ))}
              {data.contentfulSite.footerItems.map((card, index) => (
                <div key={index}>
                  {hasClass(card, "instagram-link") && (
                    <Link link={card.url} target="_blank">
                      <img alt="Instagram" src={instagramLogo} width="24px" />
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            sx={{
              gridColumnStart: ["1", "1", "3"],
              gridColumnEnd: ["3", "3", "3"],
              marginTop: ["20px", "20px", 0],
            }}
          >
            <NewsletterSignup />
            {data.contentfulSite.footerItems.map((card, index) => (
              <div key={index}>
                {hasClass(card, "footer-rights") && (
                  <p
                    sx={{
                      display: ["grid", "grid", "none"],
                      alignSelf: "end",
                      color: "#A8A294",
                      fontSize: "12px",
                      marginBottom: "14px",
                    }}
                    key={{ index }}
                  >
                    {card.title}
                  </p>
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>

      <div
        sx={{
          backgroundColor: "primary",
          height: "50px",
          width: "100%",
        }}
      >
        <Container
          sx={{
            py: 0,
            height: "50px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            {data.contentfulSite.footerItems.map((card, index) => (
              <div key={index}>
                {hasClass(card, "footer-rights") && (
                  <p
                    sx={{
                      display: ["none", "none", "block"],
                      color: "background",
                      fontSize: "14px",
                      margin: 0,
                    }}
                    key={{ index }}
                  >
                    {card.title}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              marginLeft: [0, 0, "27px"],
            }}
          >
            {data.contentfulSite.footerItems.map((card, index) => (
              <div key={index}>
                {hasClass(card, "footer-bottom") && (
                  <Link
                    link={card.url}
                    sx={{
                      color: "background",
                      fontSize: "14px",
                      margin: 0,
                      textDecoration: "underline",
                      ":first-of-type": { paddingRight: "20px" },
                      ":hover": { color: "background" },
                    }}
                    key={{ index }}
                  >
                    {card.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
