/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SiteLogo from "../../images/sfarts-logo-big.svg"
import { useWindowScroll } from "react-use"
import { use100vh } from "react-div-100vh"
import { useIsClient } from "../../hooks/use-is-client"
import HeaderDesktop from "./header-desktop"
import HeaderMobile from "./header-mobile"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        navbarItems {
          ...ContentCardData
          contentCards {
            ...ContentCardData
            contentCards {
              ...ContentCardData
            }
          }
        }
      }
      experiences: allContentfulExperience(sort: { fields: experienceName }) {
        nodes {
          experienceName
        }
      }
      categories: allContentfulDiscipline(sort: { fields: disciplineName }) {
        nodes {
          disciplineName
        }
      }
    }
  `)

  const [scrolled, setScrolled] = React.useState(false)
  const { y: scrollY } = useWindowScroll()
  React.useEffect(() => {
    if (scrollY > 50) {
      setScrolled(true)
    } else if (scrollY === 0) {
      setScrolled(false)
    }
  }, [scrollY])

  const isClient = useIsClient()
  const height = use100vh()

  return (
    <header
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        maxHeight: isClient && height ? height : "100vh",
      }}
    >
      <div sx={{ display: ["block", "block", "block", "none"] }}>
        <HeaderMobile data={data} SiteLogo={SiteLogo} />
      </div>
      <div sx={{ display: ["none", "none", "none", "block"] }}>
        <HeaderDesktop scrolled={scrolled} data={data} SiteLogo={SiteLogo} />
      </div>
    </header>
  )
}

export default Header
