/** @jsx jsx */
import { jsx } from "theme-ui"
import DesktopSection from "./desktop-section"
import querystring from "query-string"

function SubMenuContent({ cards, data, ...restProps }) {
  return (
    <div
      sx={{
        marginRight: ["0px", "0px", "2%", "2%", "8%"],
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      {cards.map((card, index) => {
        if (card.classes && card.classes.includes("categories"))
          return (
            <DesktopSection
              key={index}
              title={card.title}
              links={data.categories.nodes.map(category => ({
                title: "/" + category.disciplineName,
                url:
                  "/calendar?" +
                  querystring.stringify({
                    discipline: category.disciplineName,
                  }),
              }))}
              {...restProps}
            />
          )
        if (card.classes && card.classes.includes("experiences"))
          return (
            <DesktopSection
              key={index}
              title={card.title}
              links={data.experiences.nodes.map(experience => ({
                title: experience.experienceName,
                url:
                  "/calendar?" +
                  querystring.stringify({
                    experiences: experience.experienceName,
                  }),
              }))}
              {...restProps}
            />
          )
        return (
          <DesktopSection
            key={index}
            title={card.title}
            links={card.contentCards.map(subcard => ({
              title: subcard.title,
              url: subcard.url,
              classes: subcard.classes,
            }))}
            sx={{ marginRight: [0, 0, 0, 0, "-120px", 0] }}
            {...restProps}
          />
        )
      })}
    </div>
  )
}

export default SubMenuContent
