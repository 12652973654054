/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Box } from "@theme-ui/components"

const Container = ({
  children,
  fullWidth,
  showSeparator,
  outerStyle,
  ...restProps
}) => {
  const containerStyle = {
    maxWidth: theme =>
      theme.spacing.horizontal.map(spacing => 1200 + spacing * 2),
    minWidth: 0,
    mx: "auto",
    px: theme => theme.spacing.horizontal,
    py: theme => theme.spacing.vertical.sm,
    width: "100%",
  }

  if (fullWidth) {
    fullWidth.forEach((fullWidthValue, index) => {
      if (fullWidthValue) {
        containerStyle.maxWidth[index] = "100%"
        containerStyle.px[index] = 0
      }
    })
  }

  return (
    <div
      sx={{
        display: "flex",
        "&:last-of-type": {
          ".separator": { display: "none" },
        },
        ...outerStyle,
      }}
    >
      <Box
        sx={{
          ...containerStyle,
        }}
        {...restProps}
      >
        {children}
        {showSeparator && (
          <div
            className="separator"
            sx={{
              width: "100%",
              borderBottom: "2px solid #D5D3D3",
              mt: "30px",
            }}
          ></div>
        )}
      </Box>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.arrayOf(PropTypes.bool),
}

export default Container
