/** @jsx jsx */
import { jsx } from "theme-ui"
import SubMenuContent from "./desktop-submenu-content"
import { swatches } from "../../gatsby-plugin-theme-ui"
import Accent from "../accent"

function DesktopSubMenu({ cards, scrolled, data, ...restProps }) {
  return (
    <div
      sx={{
        width: "100%",
        backgroundColor: swatches.primary.tan,
        position: "absolute",
        left: "0",
        top: scrolled ? "66px" : "89px",
        zIndex: 999,
        height: "258px",
        display: "none",
        padding: 0,
        "&:hover": {
          display: "block",
        },
      }}
    >
      <Accent
        sx={{
          height: "258px",
          display: "flex",
          justifyContent: "flex-end",
          padding: 0,
        }}
        type="header"
        colorB={swatches.secondary.lightTan}
        {...restProps}
      >
        <SubMenuContent cards={cards} data={data} {...restProps} />
      </Accent>
    </div>
  )
}

export default DesktopSubMenu
