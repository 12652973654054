/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, link, target, forceExternal, ...restProps }) => {
  const pageLinkStyle = {
    color: "primary",
    ":hover": {
      color: "primary",
    },
    fontFamily: "body",
    fontSize: 2,
    textDecoration: "none",
  }
  const isInternal = link && link[0] === "/" && link[1] !== "/"
  const normalizedLink =
    link && link.includes && (link.includes("//") ? link : "//" + link)
  return !forceExternal && isInternal ? (
    <GatsbyLink sx={{ ...pageLinkStyle }} {...restProps} to={link}>
      {children}
    </GatsbyLink>
  ) : (
    <a
      sx={{ ...pageLinkStyle }}
      {...restProps}
      href={forceExternal && !isInternal ? normalizedLink : link}
      rel="noopener noreferrer"
      target={target || "_blank"}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
}

export default Link
