/** @jsx jsx */
import { jsx } from "theme-ui"
import { useLocation } from "@reach/router"
import React from "react"
import ReactDOM from "react-dom"
import { useStaticQuery, graphql } from "gatsby"
import Text from "./text"
import { usePrevious } from "react-use"
import { motion, AnimatePresence } from "framer-motion"
import { useIsClient } from "../hooks/use-is-client"
import { swatches } from "../gatsby-plugin-theme-ui"
import calendarIcon from "../images/calendar.svg"
import Link from "./link"
import { hasClass } from "../helpers"
import SiteLogo from "../images/sfarts-logo-big.svg"

const Modal = ({ title, children, onClose, visible, ...restProps }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        navbarItems {
          ...ContentCardData
          contentCards {
            ...ContentCardData
            contentCards {
              ...ContentCardData
            }
          }
        }
      }
    }
  `)
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const isClient = useIsClient()
  React.useEffect(() => {
    if (previousLocation && previousLocation !== location) onClose()
  }, [location, previousLocation, onClose])
  return (
    isClient &&
    ReactDOM.createPortal(
      <AnimatePresence>
        {visible && (
          <motion.div
            key={1}
            sx={{
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              p: [0, 0, 0, 5],
              background: "rgba(0,0,0,0.5)",
              zIndex: 9999,
            }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <motion.div
              sx={{
                width: "100%",
                height: "100%",
                px: [0, 0, 0, "30px"],
                pb: 6,
                background: "primary",
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
              }}
              exit={{ transform: "scale(0.8)" }}
              initial={{ transform: "scale(0.8)" }}
              animate={{ transform: "scale(1)" }}
              transition={{ duration: 0.4 }}
              {...restProps}
            >
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: ["20px", "20px", "20px", 0],
                  backgroundColor: "#313436",
                  height: "61px",
                  width: "100%",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                  }}
                >
                  {data.contentfulSite.navbarItems.map((card, index) => (
                    <div key={index}>
                      {hasClass(card, "calendar") && (
                        <Link link={card.url}>
                          <img alt="" src={calendarIcon} width="18px" />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
                <Link link="/" sx={{ alignSelf: "center" }}>
                  <img
                    src={SiteLogo}
                    alt="SFARTS-logo"
                    sx={{ width: "76px" }}
                  />
                </Link>
                <Text
                  type="h3"
                  sx={{
                    color: swatches.secondary.background,
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  onClick={onClose}
                >
                  &#10005;
                </Text>
              </div>

              <div sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                {children}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body
    )
  )
}

export default Modal
