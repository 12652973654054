/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import calendarIcon from "../../images/calendar.svg"
import { hasClass } from "../../helpers"
import { swatches } from "../../gatsby-plugin-theme-ui"
import Modal from "../modal"
import Container from "../container"
import Link from "../link"
import SubMenuContentMobile from "./mobile-submenu"

function HeaderMobile({ data, SiteLogo }) {
  const cards = data.contentfulSite.navbarItems.find(
    item => item.classes && item.classes.includes("has-submenu")
  )
  const [showMenu, setShowMenu] = React.useState(false)
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#313436",
        height: "61px",
      }}
    >
      <div
        sx={{ display: "flex", flexDirection: "column", alignSelf: "center" }}
      >
        {data.contentfulSite.navbarItems.map((card, index) => (
          <div key={index}>
            {hasClass(card, "calendar") && (
              <Link link={card.url}>
                <img alt="" src={calendarIcon} width="18px" />
              </Link>
            )}
          </div>
        ))}
      </div>
      <Link link="/" sx={{ width: "76px", ml: "10px", alignSelf: "center" }}>
        <img src={SiteLogo} alt="SFARTS-logo" />
      </Link>
      <Modal
        sx={{
          backgroundColor: swatches.secondary.lightTan,
        }}
        visible={showMenu}
        onClose={() => setShowMenu(false)}
      >
        <SubMenuContentMobile data={data} cards={cards.contentCards} />
      </Modal>
      <button
        sx={{
          pl: "10px",
          width: "30px",
          cursor: "pointer",
          color: "white",
          alignSelf: "center",
        }}
        onClick={() => setShowMenu(true)}
      >
        <div
          sx={{
            backgroundColor: "white",
            height: 2,
            mb: 1,
            width: 25,
          }}
        />
        <div
          sx={{
            height: 2,
            mb: 1,
            width: 25,
          }}
        />
        <div
          sx={{
            backgroundColor: "white",
            height: 2,
            width: 25,
          }}
        />
      </button>
    </Container>
  )
}

export default HeaderMobile
