/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import Container from "../container"
import Link from "../link"
import { swatches } from "../../gatsby-plugin-theme-ui"
import chevron from "../../images/nav-more-arrow.svg"
import DesktopSubMenu from "./desktop-submenu"

const HeaderDesktop = ({ data, scrolled, SiteLogo, ...restProps }) => (
  <div
    sx={{
      height: scrolled ? "66px" : "89px",
      color: "background",
      width: "100%",
      transition: ["unset", "unset", "height  0.5s"],
      willChange: "height",
      backgroundColor: swatches.secondary.dark,
    }}
  >
    <div
      sx={{
        height: "4px",
        backgroundColor: swatches.primary.red,
        width: "100%",
      }}
    />
    <Container
      sx={{
        display: "flex",
        height: scrolled ? "66px" : "89px",
        justifyContent: "space-between",
        p: "0",
        px: "20px",
        alignItems: "flex-end",
        transition: "height  0.5s",
        willChange: "height",
      }}
    >
      <Link link="/" sx={{ alignSelf: "center" }}>
        <img
          src={SiteLogo}
          alt="SFARTS-logo"
          sx={{
            width: scrolled ? "87px" : "130px",
            transition: "width  0.5s",
            willChange: "width",
          }}
        />
      </Link>
      <div sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {data.contentfulSite.navbarItems.map((card, index) => (
          <div key={index}>
            {card.classes && card.classes.includes("has-submenu") ? (
              <React.Fragment>
                <div
                  sx={{
                    color: swatches.secondary.background,
                    cursor: "default",
                    display: "flex",
                    alignItems: "center",
                    pb: scrolled ? "20px" : "30px",
                    fontSize: "16px",
                    fontWeight: "400",
                    transition: "padding-bottom  0.5s",
                    willChange: "padding-bottom",
                    "&:hover+div": {
                      display: "block",
                      padding: 0,
                    },
                    ":hover": {
                      color: "#cc5c45",
                    },
                  }}
                >
                  /{card.title}
                  <span>
                    <img
                      alt=""
                      src={chevron}
                      sx={{
                        marginLeft: "5px",
                        marginTop: "11px",
                      }}
                      width="13px"
                      height="15px"
                    />
                  </span>
                </div>
                <DesktopSubMenu
                  cards={card.contentCards}
                  scrolled={scrolled}
                  data={data}
                  {...restProps}
                />
              </React.Fragment>
            ) : (
              <div
                sx={{
                  pb: scrolled ? "20px" : "30px",
                  transition: "padding-bottom  0.5s",
                  willChange: "padding-bottom",
                }}
              >
                <Link
                  sx={{
                    flexGrow: "1",
                    color: swatches.secondary.background,
                    marginRight: "50px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  link={card.url}
                >
                  /{card.title}
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </Container>
  </div>
)

export default HeaderDesktop
