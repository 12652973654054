/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Spinner } from "@theme-ui/components"
import { useNewsletterSignupService } from "../services/newsletter-signup-service"
import mailIcon from "../images/mail-icon.svg"

const NewsletterSignup = () => {
  const [email, setEmail] = React.useState(null)
  const [message, setMessage] = React.useState(null)
  const { post, response } = useNewsletterSignupService()
  const onChange = e => {
    setMessage(null)
    setEmail(e.target.value)
  }
  const onSend = () => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setMessage(null)
      post({ data: { email }, callback: () => {} })
    } else setMessage("Invalid email address")
  }
  React.useEffect(() => {
    if (response.error) {
      setMessage("Internal server error")
    } else if (response.data) {
      setMessage("Thank you. Your email has been added")
    }
  }, [response])
  return (
    <div
      sx={{
        color: "background",
      }}
    >
      <h6
        sx={{
          pt: "7px",
          fontSize: "14px",
          textTransform: "uppercase",
        }}
      >
        Join Our Newsletter
      </h6>
      <div sx={{ display: "flex", flexDirection: "row", maxWidth: "300px" }}>
        <input
          placeholder="Your email address"
          disabled={response.data}
          sx={{
            flexGrow: 1,
            border: 0,
            background: "transparent",
            color: "background",
            borderBottom: "1px solid",
            borderColor: "swatches.secondary.tan",
            pr: "20px",
            pb: "10px",
            transition: "0.2s",
            ":focus": {
              borderColor: "swatches.secondary.lightTan",
            },
            "::placeholder": {
              fontStyle: "italic",
            },
          }}
          onChange={onChange}
        />
        <div sx={{ ml: "-20px" }}>
          {response.loading ? (
            <Spinner sx={{ width: "20px", height: "14px" }} />
          ) : response.data ? (
            <svg
              width="18"
              height="18"
              viewBox="3 3 18 18"
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "100%",
                border: "2px solid",
                borderColor: "primary",
                path: {
                  width: "100%",
                  height: "100%",
                  fill: "primary",
                },
              }}
            >
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
            </svg>
          ) : (
            <img
              onClick={onSend}
              src={mailIcon}
              sx={{ width: "20px", height: "14px", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      {
        <div
          sx={{
            mt: "5px",
            fontSize: "13px",
            opacity: message ? 1 : 0,
            transition: "0.4s",
          }}
        >
          {message}
        </div>
      }
    </div>
  )
}
export default NewsletterSignup
